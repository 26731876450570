import { Link, Route, Routes, useLocation } from "react-router-dom";
import AdvanceTicketing from "../components/Schedules/AdvanceTicketing";
import HshsBooking from "../components/Schedules/HshsBooking";
import PageLayout from "../components/utils/PageLayout";
import TripCodeBooking from "./TripCodeBooking";
import SearchTrip from "./SearchTrip";
import VerifyModal from "../mobile/components/VerifyModal";
import Main from "../mobile/Main";
import { useEffect, useState } from "react";
import { getCookie } from "../helpers/utils";
import { RouteConstants } from "../helpers/constants";
import { accounts } from "../helpers/api";

const Home = () => {
  const token = getCookie("oya_token");
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(true);
  const [notVerified, setNotVerified] = useState(false);

  const getUserData = async () => {
    if (token) {
      try {
        const res = await accounts.get(`${RouteConstants.PROFILE}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (res.data?.payload?.verified === true) {
          setNotVerified(false);
        } else {
          setNotVerified(true);
        }
      } catch (e) {
        //setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    if (token) {
      getUserData();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", checkScreenSize);

    checkScreenSize();

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);
  const isCurrentPath = (path: string) => location.pathname === path;
  return (
    <>
      <VerifyModal
        isOpen={notVerified}
        handleClose={() => setNotVerified(false)}
      />
      {isMobile ? (
        <Main />
      ) : (
        <PageLayout>
          <div className="flex justify-center items-center w-full pt-20">
            <div className="w-full py-8 flex flex-col ml-[8rem]">
              <div className="flex justify-between items-center mb-[2rem]">
                <div className="flex items-center gap-4">
                  <div className="flex items-center gap-4">
                    <Link
                      to="/"
                      className={
                        isCurrentPath("/")
                          ? "text-white bg-oya-red-100 py-[8px] px-[2px] sm:px-[24px] rounded-lg sm:text-[16px] text-xs"
                          : "sm:text-[16px] rounded-lg border text-xs text-oya-red-500"
                      }
                    >
                      Find a trip
                    </Link>

                    <Link
                      to="/trip-code"
                      className={
                        isCurrentPath("/trip-code")
                          ? "text-white bg-oya-red-100 py-[8px] px-[2px] sm:px-[24px] rounded-lg sm:text-[16px] text-xs"
                          : "sm:text-[16px] text-oya-red-500 text-xs"
                      }
                    >
                      Use trip code
                    </Link>

                    <Link
                      to="/book-advance"
                      className={
                        isCurrentPath("/book-advance")
                          ? "text-white bg-oya-red-100 py-[8px] px-[2px] sm:px-[24px] rounded-lg sm:text-[16px] text-xs"
                          : "sm:text-[16px] text-xs text-oya-red-500 py-[6px] px-[2px] sm:px-[24px]"
                      }
                    >
                      Book a trip in advance
                    </Link>

                    <Link
                      to="/hshs"
                      className={
                        isCurrentPath("/hshs")
                          ? "text-white bg-oya-red-100 py-[8px] px-[2px] sm:px-[24px] rounded-lg sm:text-[16px] text-xs"
                          : "sm:text-[16px] text-xs text-oya-red-500 py-[6px] px-[2px] sm:px-[24px]"
                      }
                    >
                      HSHS
                    </Link>
                  </div>
                </div>
              </div>
              <Routes>
                <Route path="/" element={<SearchTrip />} />
                <Route path="/trip-code" element={<TripCodeBooking />} />
                <Route path="/book-advance" element={<AdvanceTicketing />} />
                <Route path="/hshs" element={<HshsBooking />} />
              </Routes>
            </div>
          </div>
        </PageLayout>
      )}
    </>
  );
};

export default Home;
