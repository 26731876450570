import HshsBranches from "../Hshs/HshsSchedules";

const HshsBooking = () => {
  return (
    <div>
      <HshsBranches />
    </div>
  );
};

export default HshsBooking;
