import { useState } from "react";
import { RouteConstants } from "../../helpers/constants";
import { Row, Col, Skeleton, Pagination } from "antd";
import { api } from "../../helpers/api";
import CustomButton from "../CustomBtn";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import notFound from "../../assets/img/not_found.svg";
import LoadingPointScheduleCard from "../Schedules/LoadingPointScheduleCard";

function HshsSchedules() {
  const [limit, setLimit] = useState(50);
  const [total, setTotal] = useState(0);
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageSize: 12,
    pageIndex: 0,
  });

  const handlePagination = (page: number) => {
    setPagination({
      ...pagination,
      pageIndex: page - 1,
    });
  };

  const searchLoadingPointSchedules = async () => {
    const filter: any[] = [];
    setLimit(50);
    try {
      const { data } = await api.get(
        `${
          RouteConstants.SEARCH_LOADING_POINT_SCHEDULES
        }?filters=${JSON.stringify(filter)}&limit=${limit}`
      );

      setTotal(data?.payload?.total);

      return data?.payload?.items;
    } catch (error: any) {
      throw new Error(error?.response?.data?.message || error?.message);
    }
  };

  const { isLoading, data } = useQuery({
    queryKey: ["search-loading-point-schedules"],
    queryFn: () => {
      return searchLoadingPointSchedules();
    },
  });

  return (
    <div className="flex gap-3">
      <div className="w-full h-screen">
        {isLoading ? (
          <Row className="mx-3 gap-3">
            <Col xs={24} md={12} lg={7}>
              <div className="p-2 border shadow rounded-md">
                <Skeleton paragraph active />
              </div>
            </Col>
            <Col xs={24} md={12} lg={7}>
              <div className="p-2 border shadow rounded-md">
                <Skeleton paragraph active />
              </div>
            </Col>
            <Col xs={24} md={12} lg={7}>
              <div className="p-2 border shadow rounded-md">
                <Skeleton paragraph active />
              </div>
            </Col>
          </Row>
        ) : (
          <>
            {data.length < 1 ? (
              <div className=" py-3 ">
                <div className="flex flex-col justify-center items-center h-full">
                  <img
                    className="w-[50%] sm:w-[40%] h-auto mt-8"
                    src={notFound}
                    alt="404"
                  />
                  <div className="font-bold sm:text-xl text-center mt-8 text-gray-500">
                    No schedules available
                  </div>
                  <div className="mt-2">
                    <CustomButton
                      onClick={() => {
                        navigate("/book-advance");
                      }}
                    >
                      Book for advance ticket
                    </CustomButton>
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5">
                  {data.map((item: any, index: number) => (
                    <div className="w-full min-w-[220px]" key={index}>
                      <LoadingPointScheduleCard item={item} />
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        )}

        <div className="pt-10 w-full flex justify-end items-end">
          {total > pagination.pageSize && (
            <div className="pagination w-full justify-end items-end">
              <Pagination
                pageSize={pagination.pageSize}
                current={pagination.pageIndex + 1}
                total={total}
                onChange={(page) => {
                  handlePagination(page);
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default HshsSchedules;
